import { createStore, applyMiddleware, compose } from "redux";

// middlewares
import { thunk } from 'redux-thunk';

// Import custom components
import rootReducer from "./reducers/index";

// Import persist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: 'persistKey',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create a Redux store
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { persistor };

export default store;
