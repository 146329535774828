import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import Toast from "../../components/shared/Toast";

const HASHTAG_LIST_URL = "/framework/api/hashtag";
const HASHTAG_DELETE_URL = "/framework/api/delete-Hashtag";
const HASHTAG_ADD_URL = "/framework/api/manageHashtags"
const HASHTAG_EDIT_URL = "/framework/api/update-Hashtag"
const EXPORT_LEADS = "framework/api/download_leaderboard_report";

const CATEGORY_LIST_URL = "/framework/api/Categories-list";
const CATEGORY_SEQUENCE = "/framework/api/updateCatgorySequence";
const CATEGORY_ADD_URL = "/framework/api/add-category";
const CATEGORY_DELETE_URL = "/framework/api/delete-category";
const CATEGORY_EDIT_URL = "/framework/api/update-category";
const CATEGORY_ACTIVE_URL = "/framework/api/changeCategoryStatus";

const CONTENT_LIST_URL = "/framework/api/content-list";
const CONTENT_ADD_URL = "/framework/api/add-content-type"
const CONTENT_EDIT_URL = "/framework/api/update-content-type"
const CONTENT_DELETE_URL = "/framework/api/delete-content-type";

const PRODUCT_TYPE_LIST_URL = "/framework/api/product-types";
const PRODUCT_ADD_URL = "/framework/api/add_product_type";
const PRODUCT_DELETE_URL = "/framework/api/delete-product-type";

const GLOBAL_SEARCH_URL = "/framework/api/globalSearch";

const BUSINESS_NEED_URL = "/framework/api/listtype";
const BUSINESS_NEED_ADD = "/framework/api/addtype";
const BUSINESS_NEED_EDIT = "/framework/api/edittype";
const BUSINESS_NEED_DELETE = "/framework/api/deletetype";
const PARENT_LISTING = "/framework/api/partners"
const PARENT_CATEGORY_LISTING = "/framework/api/partner-category"
const LEADERBOARD = "/framework/api/LeaderBoard"
const LEADERBOARD_LEVELS = "/framework/api/badges"
const LEADER_REPORT_URL = "/framework/api/leaderboard_listing"


// SET Unauthorization Status
export const setUnauthorizedSetup = () => {
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_SETUP", payload: null });
  };
};

export const currentUrlPath = () => {
    return (dispatch) => {
        dispatch({ type: "CURRENT_URL_PATH", payload: null });
    };
};

export const searchInput = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_INPUT, payload: value });
    };
};

//Export Lead Details
export const exportLeadDetails = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                EXPORT_LEADS,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    var filename = "Leads";
                    filename = filename + ".csv";
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/xlsx" })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    Toast("Exported Successfully", "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing
export const fetchLeaderReport = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADER_REPORT_URL,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_LEADERBOARD_REPORT, payload: null });
                    await dispatch({ type: types.FETCH_LEADERBOARD_REPORT, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing
export const fetchBusinessNeed = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                BUSINESS_NEED_URL,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_BUSINESS_NEED_LIST, payload: null });
                    await dispatch({ type: types.FETCH_BUSINESS_NEED_LIST, payload: response.data });
                    dispatch({ type: "CURRENT_PAGE", payload: 0 });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add addBusinessNeed
export const addBusinessNeed = (authToken, title) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
        try {
            const response = await storeApis.post(
                BUSINESS_NEED_ADD,
                {
                    type:title.name
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: true });
                    dispatch(fetchBusinessNeed(authToken));
                    Toast(response.data.message, "S");
                    // window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Edit editBusinessNeed 
export const editBusinessNeed = (authToken, title) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
        try {
            const response = await storeApis.post(
                BUSINESS_NEED_EDIT,
                {
                    type_id : title.id,
                    type : title.name
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: true });
                    dispatch(fetchBusinessNeed(authToken));
                    Toast(response.data.message, "S");
                    // window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete BussinessData
export const deleteBusinessNeed = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                BUSINESS_NEED_DELETE,
                {
                   type_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    dispatch(fetchBusinessNeed(authToken));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const setProductAttribute = (value, s_id, desc) => {
    return (dispatch) => {
        dispatch({ type: types.SET_NAME_ATTR, payload: value });
        dispatch({ type: types.SET_DESC_ATTR, payload: desc });
        dispatch({ type: types.SET_SUB_ID, payload: s_id });
    };
};

export const setCategoryAttribute = (value ,type, selected, p_id, s_id, image, s_code) => {
    return (dispatch) => {
        dispatch({ type: types.SET_NAME_ATTR, payload: value });
        dispatch({ type: types.SET_ATTR_TYPE, payload: type });
        dispatch({ type: types.SET_PARENT_ID, payload: p_id });
        dispatch({ type: types.SET_SUB_ID, payload: s_id });
        dispatch({ type: types.SET_SELECTED_VALUE, payload: selected });
        dispatch({ type: types.SET_IMAGE, payload: image });
        dispatch({ type: types.SET_SCHEMA_CODE, payload: s_code });
    };
};

export const setContentAttribute = (value, selected, p_id, s_id, image, desc, internal ) => {
    return (dispatch) => {
        dispatch({ type: types.SET_NAME_ATTR, payload: value });
        dispatch({ type: types.SET_ATTR_TYPE, payload: internal });
        dispatch({ type: types.SET_DESC_ATTR, payload: desc });
        dispatch({ type: types.SET_PARENT_ID, payload: p_id });
        dispatch({ type: types.SET_SUB_ID, payload: s_id });
        dispatch({ type: types.SET_SELECTED_VALUE, payload: selected });
        dispatch({ type: types.SET_IMAGE, payload: image });
    };
};
export const setBatchAttribute = (icon, name, date, id, point ) => {
    return (dispatch) => {
        dispatch({ type: types.BATCH_VALUES, payload: {icon:icon, name:name, date:date, id:id, point:point} });
    };
};


// fetchParentListing
export const fetchParentListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                    action:"list"
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PARENT_LIST, payload: null });
                    dispatch({ type: types.FETCH_PARENT_LIST, payload: response.data.response });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetchParentCategoryListing

export const fetchPartnerCategoryListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_CATEGORY_LISTING,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                    action:"list"
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PARENT_CATEGORY_LIST, payload: null });
                    dispatch({ type: types.FETCH_PARENT_CATEGORY_LIST, payload: response.data.response });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add Parent Category
export const addPartnerCategory = (authToken,value,lang_value) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_CATEGORY_LISTING,
                {
                  action: "add",
                  partner_category: value,
                  language_param:lang_value == "All" ? 0 : 1
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchPartnerCategoryListing(authToken));
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
// Update Parent Category 
export const updatePartnerCategory = (authToken,value,lang_value,id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_CATEGORY_LISTING,
                {
                  action: "update",
                  partner_category: value,
                  language_param:lang_value && lang_value == "All" ? 0 : 1,
                  partner_category_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchPartnerCategoryListing(authToken));
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};


export const fetchAddPartnerData = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    action:"partner_details",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.ADD_PARTNER, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};



export const fetchDigicardData = (authToken, value, onSuccess) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/getDigiCardData",
                {
                    id:value.id,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // await dispatch({ type: types.ADD_PARTNER, payload: response.data.response });
                    debugger;
                    await onSuccess(response.data.response);
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Update Parent Category 
export const saveDigicard = (authToken, payload) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/updateDigiCard",
                payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// updateUidNumber
export const updateUidNumber = (authToken, id, uid) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/updateUrn",
                {
                    id: id,
                    urn_no: uid
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    dispatch(editPartner(authToken ,id))
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};


export const editPartner = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    action:"edit_partner",
                    id:id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.EDIT_PARTNER, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

///////////////////LeaderBoard////////////////////////
export const getLeaderboard = (authToken, action) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD,
                {
                    action:action,
                    // activity_id: id ? id :"",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
                );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    if(action ==="activity_condition_dropdown"){
                        await dispatch({ type: types.LEADERBOARD_DROPDOWN, payload: response.data.response });
                    }else{
                        await dispatch({ type: types.LEADERBOARD_LISTING, payload: response.data.response });
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const addLeaderBoard = (authToken, payloadData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD,
                {
                    action: "add",
                    data: payloadData
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(getLeaderboard(authToken, "list"));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
export const deleteLeaderBoard = (authToken, deleteData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD,
                {
                    action: "delete",
                    id: deleteData
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        if(response.data.response=="Ids to be deleted are missing"){
                            dispatch(getLeaderboard(authToken, "list"));
                            dispatch({ type: "TOGGLE_LOADING", payload: false });
                            window.scrollTo(0, 0)
                        }else{
                            Toast(response.data.response, "E");
                        }
                        // window.reload();
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(getLeaderboard(authToken, "list"));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const fetchLevelsListing = (authToken,searchValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD_LEVELS,
                {
                    action: "list",
                    search: searchValue ? searchValue : "",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_LEVELS_LIST, payload: null });
                    dispatch({ type: types.FETCH_LEVELS_LIST, payload: response.data });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    // Toast(response.data.response, "S");
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
export const addBatch = (authToken,badgeName,badgeIcon,badgePoints,id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD_LEVELS,
                {
                    action: "add",
                    badge_name:badgeName,
                    badge_icon:badgeIcon ? badgeIcon : "",
                    points:badgePoints,
                    id: id ? id :""
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.history.back()
                    // Toast(response.data.response, "S");
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const deleteBatch = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEADERBOARD_LEVELS,
                {
                action: "delete",
                id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    dispatch(fetchLevelsListing(authToken,""));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

///////////////////LeaderBoard////////////////////////

// EDIT PARTNER INFO SAVE
export const updatePartner = (authToken, payloadData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                payloadData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                    // window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
export const addPartner = (authToken, payloadData, { heirarchy_check }) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                payloadData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: "PARTNER_ID_H", payload: response.data.partner_id });
                    if (heirarchy_check === "pass") {
                        await dispatch(fetchDesignation(authToken, response.data.partner_id));
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Reactivate Account
export const reactivateAccount = (authToken, username) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {action:"reactivate_account",username:username},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.response, "S");
                    dispatch(fetchParentListing(authToken, 0));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
// Base Type Parent
export const baseTypeParent = (authToken, searchInput, partner_id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {action:"base_type_parent_options","startlimit":0,"endlimit":10,"search":searchInput,"partner_id":partner_id},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    await dispatch({ type: types.BASE_TYPE_PARENT, payload: response.data.response });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// SAVE ALL DESIGANTION & HEIRARCHY DATA
export const saveAllPartnerData = (authToken, p_id, heirarchy) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    action: "update_designation_hierarchy",
                    partner_id: p_id ,
                    hierarchy: heirarchy,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "CURRENT_PAGE", payload: 0 });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                    window.history.back()
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
}

export const fetchDesignation = (authToken,p_id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    action: "designation_dropdown",
                    partner_id: parseInt(p_id)
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: types.FETCH_DESIGNATION_LIST, payload: response.data.response });
                    dispatch({ type: types.FETCH_DESIGNATION_LIST_SUPPORT, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const selectDesignation = (authToken, incomingCall, order, list_support, designation, selectedUser, p_id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_LISTING,
                {
                    action: "designation_dropdown",
                    designation_id: designation,
                    user_id: selectedUser,
                    partner_id: parseInt(p_id)
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    list_support = list_support.concat(response.data.response);
                    list_support = list_support.map((key, index) => {
                        if (index > order) { key.selected_value = []; }
                        return key;
                    });
                    // await dispatch({ type: types.FETCH_PARENT_LIST, payload: null });
                    await dispatch({ type: types.FETCH_DESIGNATION_LIST, payload: response.data.response });
                    await dispatch({ type: types.FETCH_DESIGNATION_LIST_SUPPORT, payload: list_support});
                    // dispatch({ type: "CURRENT_PAGE", payload: 0 });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Global search listing
export const fetchGlobalSearchListing = (authToken, type, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                GLOBAL_SEARCH_URL,
                {
                    action: type,
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    if (type === "successfull") {
                        await dispatch({ type: types.FETCH_GLOBAL_SEARCH_LIST, payload: null });
                        await dispatch({ type: types.FETCH_GLOBAL_SEARCH_LIST, payload: response.data.response }); 
                    } else {
                        await dispatch({ type: types.FETCH_GLOBAL_SEARCH_LIST_U, payload: null });
                        await dispatch({ type: types.FETCH_GLOBAL_SEARCH_LIST_U, payload: response.data.response }); 
                    }
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add Global Search
export const addGlobalSearch = (authToken, title) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                GLOBAL_SEARCH_URL,
                {
                    action: "add",
                    search_text: title
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchGlobalSearchListing(authToken, "successfull"));
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete Bulk Global Search
export const deleteBulkGlobalSearch = (authToken, selectedList ,type, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                GLOBAL_SEARCH_URL,
                {
                  action: "delete",
                  ids: selectedList.web
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchGlobalSearchListing(authToken, type, page));
                    dispatch(searchInput(""));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Mark Popular Bulk Global Search
export const bulkPopularityGlobalSearch = (authToken, selectedList ,type, action,page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                GLOBAL_SEARCH_URL,
                {
                  action: action,
                  ids: selectedList.web
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchGlobalSearchListing(authToken, type, page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch ProductTypeListing 
export const fetchProductTypeListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PRODUCT_TYPE_LIST_URL,
                {
                    startlimit: startlimit ? startlimit : 0,
                    limit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                    id:"",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PRODUCT_TYPE_LIST, payload: null });
                    dispatch({ type: types.FETCH_PRODUCT_TYPE_LIST, payload: response.data.response });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add addProductTypeData
export const addProductTypeData = (authToken, name, desc, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PRODUCT_ADD_URL,
                {
                    id: id ? id : "",
                    title: name,
                    description: desc
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PRODUCT_TYPE_LIST, payload: null });
                    Toast(response.data.message, "S");
                    window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete ProductType
export const deleteProductTypeData = (authToken, cat_id, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PRODUCT_DELETE_URL,
                {
                    action: "delete",
                    id: [cat_id]
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PRODUCT_TYPE_LIST, payload: null });
                    await dispatch(fetchProductTypeListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch content listing
export const fetchContentListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CONTENT_LIST_URL,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CONTENT_LIST, payload: null });
                    await dispatch({ type: types.FETCH_CONTENT_LIST, payload: response.data });
                    dispatch({ type: types.CONTENT_LIST_DROPDOWN, payload: response.data });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add ContentData
export const addContentData = (authToken,fileData, name, desc, cat_id, internal) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
        try {
            const response = await storeApis.post(
                CONTENT_ADD_URL,
                {   content_name:name,
                    image: fileData,
                    content_type_id: cat_id,
                    description: desc,
                    internal: internal === true ? 1 : 0,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: true });
                    // dispatch(fetchContentListing(authToken));
                    Toast(response.data.message, "S");
                    window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Edit ContentData 
export const editContentData = (authToken, fileData, name, cat_id, internal, sub_id, desc) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
        try {
            const response = await storeApis.post(
                CONTENT_EDIT_URL,
                {
                    content_name: name,
                    content_id: sub_id,
                    image: fileData,
                    content_type_id: cat_id,
                    description: desc,
                    internal: internal === true ? 1 : 0,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: true });
                    // await dispatch(fetchContentListing(authToken));
                    Toast(response.data.message, "S");
                    window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete ContentData
export const deleteContent = (authToken, cat_id, type, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CONTENT_DELETE_URL,
                {
                    id: [cat_id],
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CONTENT_LIST, payload: null });
                    await dispatch(fetchContentListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch category listing
export const fetchCategoryListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_LIST_URL,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    dispatch({ type: types.FETCH_CATEGORY_LIST, payload: response.data });
                    dispatch({ type: types.SPECIAL_USER, payload: response.data.buy_now_link === 1 ? true : false });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch category listing dragging sequence
export const categoryListSequencing = (authToken, id, position, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_SEQUENCE,
                {
                    category_id: id,
                    order: page+position
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchCategoryListing(authToken,page));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add CategoryData
export const addCategoryData = (authToken,fileData, name, cat_id, code) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_ADD_URL,
                {   category_name:name,
                    image: fileData,
                    category_parent_id: cat_id,
                    sch_code: cat_id ? code : null
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    Toast(response.data.message, "S");
                    window.history.back()
                    // await dispatch(fetchCategoryListing(authToken));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Edit CategoryData 
export const editCategoryData = (authToken,fileData, name, cat_id, type, sub_id,code) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_EDIT_URL,
                {   category_name:name,
                    image: fileData,
                    category_parent_id: type === 1 ? null : cat_id,
                    id: type === 1 ? cat_id : sub_id,
                    sch_code: cat_id ? code : null,
                    type: type
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchCategoryListing(authToken,0));
                    Toast(response.data.message, "S");
                    window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Active CategoryData
export const activeCategoryData = (authToken, item_id, status, type, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_ACTIVE_URL,
                {
                    type: type,
                    status: status,
                    item_id: item_id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    await dispatch(fetchCategoryListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete CategoryData
export const deleteCategory = (authToken, cat_id, type, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CATEGORY_DELETE_URL,
                {
                    id: [cat_id],
                    type: type
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
                    await dispatch(fetchCategoryListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Fetch Hashtag List
export const fetchHashtagListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                HASHTAG_LIST_URL,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({
                        type: types.FETCH_HASHTAG_LIST,
                        payload: null,
                    });
                    dispatch({
                        type: types.FETCH_HASHTAG_LIST,
                        payload: response.data.response,
                    });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "SORT_VALUE", payload: sortValue });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add hashtag Data
export const addHashtag = (authToken,value) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                HASHTAG_ADD_URL,
                {
                  action: "add",
                  tag_name: value
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchHashtagListing(authToken));
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Edit hashtag
export const editHashtagName = (authToken,value,page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                HASHTAG_EDIT_URL,
                {
                  id: value.id,
                  internal_hashtag: value.internal_hashtag
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchHashtagListing(authToken,page));
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

//Delete Bulk Hashtag
export const deleteBulkListing = (authToken, selectedList, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                HASHTAG_DELETE_URL,
                {
                  action: "delete",
                  id: selectedList.web
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // dispatch({
                    //     type: types.FETCH_HASHTAG_LIST,
                    //     payload: response.data.response,
                    // });
                    await dispatch(fetchHashtagListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete partner
export const deletePartner = (authToken, selectedList, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
            PARENT_LISTING,
                {
                  action: "delete",
                  partner_ids: selectedList.web
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // dispatch({
                    //     type: types.FETCH_HASHTAG_LIST,
                    //     payload: response.data.response,
                    // });
                    await dispatch(fetchParentListing(authToken, page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};
// Delete partner Category
export const deletePartnerCategory = (authToken, selectedList, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PARENT_CATEGORY_LISTING,
                {
                  action: "delete",
                 ids: selectedList.web
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    // dispatch({
                    //     type: types.FETCH_HASHTAG_LIST,
                    //     payload: response.data.response,
                    // });
                    await dispatch(fetchPartnerCategoryListing(authToken, page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};