import react from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { activeCategoryData } from "../../redux/actions/setupActions";
import { useState, useEffect } from "react";

export default function SubTable(props) {
  const type = 2;
  const dispatch = useDispatch();
  const [isSubActive, setIsSubActive] = useState(false);
  const handleActiveChange = (e,id) => {
    setIsSubActive(!isSubActive)
    dispatch(activeCategoryData(props.authToken, id, e.target.checked !== true ? 2 : 1, type, props.page));
  }
  const page = "sub_cat_table"
    const [childId, setChildId] = useState(0);
    useEffect(() => {
        let id = props.subUser[0];
        setChildId(id.it_type_id)
    }, [])
    
    return (
      <>
        { props.pid === childId && (
          <div className="sub-table">
            <h5 className="sub-table-heading">Sub Categories</h5>
            {props.subUser.map((item) => {
              return (
                <div className="row">
                  <div className="text-aligning table-name">
                    <span>{item.it_subcat && item.it_subcat.slice(0, 18)}{item.it_subcat && item.it_subcat.length > 20 && <span>...</span>}</span>
                  </div>
                  <div className="text-aligning" style={{width:"13%"}}>
                    <span className="bordering-date">11.04.2022</span>
                  </div>
                  {/* <div className="edit-icon">
                    <Icon.ImageFill color="#64B6E7" />
                    <span className="px-1">view media</span>
                  </div> */}
                  <div className="column-styling" style={{textAlign:"left"}}>
                      <span className="edit-icon" style={{borderLeft:"1px solid rgba(33, 33, 33, 0.12)"}} onClick={() => { props.edit_category(item.it_subcat, type, item.it_type_id, item.id, page, null, item.sch_code) }}>
                        <Icon.PencilSquare color="#64B6E7" />
                        Edit
                      </span>
                  </div>
                  <div className="column-styling" style={{textAlign:"left",width:"10%"}}>
                    <span className="edit-icon p-0" onClick={()=>{props.handleShowPopup(item.id,type)}}>
                    {/* <span className="edit-icon"> */}
                      <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                    </span>
                  </div>
                  {props.subPageName === "category" && <div style={{textAlign:"left",paddingTop:"4px"}} className="column-styling">
                    <Form.Check 
                      className="d-inline"
                      type="switch"
                      id="custom-switch"
                      defaultChecked={item.status}
                      onChange={(e) => {
                        handleActiveChange(e, item.id);
                      }}
                    />
                  </div>}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
}
