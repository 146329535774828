/* eslint-disable */
import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import './header/Header.css';
// import '../../../node_modules/jodit/build/jodit.min.css';

const config = {
    toolbarAdaptive: true,
    buttons: [
        {
            name: 'First Name',
            tooltip: 'FirstName',
            exec: (editor) => {
                editor.s.insertHTML('-firstName-');
            }
        },
        {
            name: 'Last Name',
            tooltip: 'LastName',
            exec: (editor) => {
                editor.s.insertHTML('-lastName-');
            }
        },
        {
            name: 'Phone Number',
            tooltip: 'Phone Number',
            exec: (editor) => {
                editor.s.insertHTML('-phone-');
            }
        },
        {
            name: 'Email',
            tooltip: 'Email',
            exec: (editor) => {
                editor.s.insertHTML('-email-');
            }
        },
        /* {
            name: 'Company Website',
            tooltip: 'Email',
            exec: (editor) => {
                editor.s.insertHTML('-email-');
            }
        }, */
    ]
}

const Editor = ({ initialContent, /* config, */ setEmailBody }) => {
   
    const editor = useRef(null);

    return (
        <JoditEditor
            ref={editor}
            value={initialContent}
            config={config}
            tabIndex={1} // tabIndex of textarea
            //onBlur={newContent => setEmailBody(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { setEmailBody(newContent) }}
        />
    )
}

export default Editor;