/* eslint-disable */
import { Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import "./Folder.css";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import { fetchFolderContentList, globalFilterAssetLibraryContentList } from "../../../../redux/actions/actions";

const FolderList = (props) => {
  const [hideFolder, setHideFolders] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.assetLibrary.list_loading);

  const folderList = useSelector((state) => state.assetLibrary.folderList);

  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  return (
    <>
      { !hideFolder &&
        <>
          {
            isLoading ? 
            <div className="folder-list-container">
                <div className="folder-list-wrapper" style={{overflowX: 'unset'}}>
                  {
                    ["item1","item2","item3","item4","item5","item6"].map((el,index) => {
                      return (
                        <Card className="smart-folder w-100" key={index}>
                          <Card.Body key={`item${index}`}>
                            <div className="smart-folder-container position-relative">
                              <div className="shimmer-folder-text shine"></div>
                            </div>
                            <div className="folder-files">
                              <div
                                className="recent-file shine"
                                style={{ height:'80px'}}
                            ></div>
                            <div
                                className="recent-file shine"
                                style={{ height:'80px'}}
                              ></div>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                    })
                  }
                </div>
                <div className="create-folder-wrapper">
                  <Card className="smart-folder">
                    <Card.Body>
                      <div className="create-new-folder">
                        <span className="add-icon">
                          <Icon.Plus />
                        </span>
                        <span>Create New Folder</span>
                      </div>
                    </Card.Body>
                  </Card>
              </div>
            </div>
              :
            <div className="folder-list-container">
              {
                <div
                  className={
                    folderList && folderList.length > 6 ? "folder-list-wrapper" : ""
                  }
                >
                  {folderList &&
                    folderList.map((folder) => {
                      return (
                        <a
                          key={folder.id}
                          href={() => false}
                          onClick={() => {
                            //dispatch(fetchFolderContentList(props.authToken, folder.id, folder.smart_folder, folder.folder_name));
                            setHideFolders(true);
                            dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, folder.id, folder.smart_folder, folder.folder_name));
                            props.setCurrentFolder(folder.folder_name);
                          }}
                        >
                          <Card className="smart-folder">
                            <Card.Body>
                              <div className="smart-folder-container">
                                <div className="folder-name">{folder.folder_name}</div>
                                <div className="file-counts">
                                  {folder.total_content}
                                </div>
                                {folder.smart_folder &&
                                  (folder.smart_folder === "1" ? (
                                    <div className="folder-tag"></div>
                                  ) : (
                                    ""
                                  ))}
                              </div>
                              <div className="folder-files">
                                {folder.images &&
                                  folder.images.map((img) => {
                                    return (
                                      <div
                                        className="recent-file"
                                        style={{ backgroundImage: `url(${img})` }}
                                      ></div>
                                    );
                                  })}
                              </div>
                            </Card.Body>
                          </Card>
                        </a>
                      );
                    })}
                </div>
              }
              <div className="create-folder-wrapper">
                <Link to="/create-folder">
                  <Card className="smart-folder">
                    <Card.Body>
                      <div className="create-new-folder">
                        <span className="add-icon">
                          <Icon.Plus />
                        </span>
                        <span>Create New Folder</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </div>
          }
        </>
      }
      {!hideFolder ? (
        <div className="hr-divider">
          <hr />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FolderList;
