/* eslint-disable */
import Navigation from "./Navigation";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "./Header.css";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  searchAssetLibraryContentList,
  fetchAssetLibraryContentList,
  removeSuggestionList,
  searchSuggestionList,
  fetchAllLeadDetails,
  fetchNewLeadDetails,
  fetchEngagedLeadDetails,
  fetchDealLeadDetails,
  fetchQuoteLeadDetails,
  fetchWonLeadDetails,
  fetchDroppedLeadDetails,
  exportLeadDetails,
  globalFilterAssetLibraryContentList,
} from "../../../redux/actions/actions";

import * as types from '../../../constants/ActionTypes';


const Header = (props) => {
  const dispatch = useDispatch();

  const authToken = props.authToken;

  let navigate = useNavigate();

  const [searchField, setSearchField] = useState(null);
  const [newSearchField, setNewSearchField] = useState([]);

  let leaderboardFlag = useSelector((state) => state.setupScreen.leaderboard_flag);
  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  const [isSearchActive, setIsSearchActive] = useState(false);


  let searchRef = useRef(null);

  let suggestionList = useSelector(
    (state) => state.assetLibrary.suggestionList
  );

  const directPublish = useSelector(
    (state) => state.assetUpload.directPublish
  );

  const notifyMsg = useSelector((state) => state.assetLibrary.notificationMsg);

  useEffect(() => {
    // setSearchField(null);
    // //searchRef.current.value = searchRef.current.value !==null ? '' : '';
    // dispatch({ type: types.SET_GLOBAL_SEARCH_KEY, payload: null });
    // dispatch(globalFilterAssetLibraryContentList());
    dispatch(searchSuggestionList(authToken));
  }, []);


  useEffect(() => {
    if (globalSearchKey == null) {
      setSearchField("");
      searchRef = null;
    } else {
      setSearchField(globalSearchKey);
      searchRef = globalSearchKey;
    }
  }, [globalSearchKey])

  useEffect(() => {
    let newlist = []
    if (searchField) {
      newlist = suggestionList.filter((s) => {
        if (s.search_text.includes(searchField)) {
          //console.log("s",s);
          return s;
        }
      })
    } else {
      setNewSearchField(suggestionList);
    }
    setNewSearchField(newlist);
  }, [searchField]);

  // console.log("globalvalue", globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName);

  const searchSubmitHandler = (e, searchKey, action) => {
    e.preventDefault();
    setIsSearchActive(false);
    if (searchKey === "clear") {
      setSearchField(null);
      searchRef.current.value = null;
      // console.log("clear search");
      dispatch(globalFilterAssetLibraryContentList(authToken, null, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
    } else {
      if (searchKey) {
        //dispatch(searchAssetLibraryContentList(authToken, searchKey));
        dispatch(globalFilterAssetLibraryContentList(authToken, searchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
        setSearchField(null);
        searchRef.current.value = searchKey;
      } else {
        setSearchField(null);
        // console.log("search in else", searchKey);
        searchRef.current.value = searchKey;
        //dispatch(searchAssetLibraryContentList(searchKey));
        dispatch(globalFilterAssetLibraryContentList(authToken, searchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
        //dispatch(fetchAssetLibraryContentList(authToken));
        dispatch(searchSuggestionList(authToken));
      }
    }

  };

  //console.log("globalSearchKey",globalSearchKey);

  const refreshPage = () => {
    dispatch({ type: types.CURRENT_PAGE, payload: 0 });
  }

  const removeSuggKeyHandler = (suggKey) => {
    dispatch(removeSuggestionList(authToken, suggKey));
    suggestionList = suggestionList.filter((item) => item.id !== suggKey);
    setTimeout(() => {
      dispatch(searchSuggestionList(authToken));
    }, 2000);
    setNewSearchField(suggestionList);
    //console.log("suggKey",suggKey);
    //console.log("suggestionList",suggestionList);
  };

  document.body.classList.toggle("overflow-hidden", isSearchActive);

  //console.log("suggestionList",suggestionList);

  return (
    <div className="header-div">
      <Navigation
        authToken={props.authToken}
        topMenuDataHandler={props.topMenuDataHandler}
        logoutDataHandler={props.logoutDataHandler}
      />
      <div style={{ marginTop: "55px" }} className={props.pageType === 'CRM Details' || props.pageType === 'CRM Imports' ? 'main-container' : ''}>
        <Container className={(props.pageType === "CRM" ? " " : "asset-search-row") + " " + (props.pageType === "Setup" ? "setup-search-row" : "") + " " + ((props.pageType === "leadReport" || props.pageType === "cta") && "d-none")}> {/* props.pageType === "CRM" ? "crm-search-row" : "asset-search-row" */}
          <Row className="custom-grid">
            {/* props.pageType === "Setup" || */}
            {props.pageType === "Asset Library" || props.pageType === "Global Fields" ? (
              <Col className="col-lg-12 col-sm-12 mx-md-2 col-12 col">
                <Form className="position-relative"
                  onSubmit={(e) =>
                    searchSubmitHandler(e, searchRef.current.value, props.pageType)
                  }
                >

                  <div className={isSearchActive ? "fixed-global-search" : "custom-flexx"}>
                    <h3 className="search-title mb-4">Asset Library</h3>
                    <div className="absolute-search-bar ">
                      <div className="position-relative mx-md-0 mx-2">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search library"
                          className="search-field"
                          ref={searchRef}
                          value={searchField}
                          onChange={(e) => {
                            setSearchField(e.target.value);
                            setIsSearchActive(true);
                          }}
                        />

                        {/* {!globalSearchKey && <div className={"search-icon " + (props.pageType === "Setup" ? "setup-screen": "")}> */}
                        {!globalSearchKey && <div className="search-icon">
                          <Icon.Search
                            onClick={(e) => {
                              searchSubmitHandler(e, searchRef.current.value);
                            }}
                          />
                        </div>}
                      </div>


                      {globalSearchKey &&
                        <div className="search-icon">
                          <Icon.X
                            onClick={(e) => {
                              searchSubmitHandler(e, null);
                            }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  {isSearchActive === true && (
                    <>
                      <div className="global-search-div">
                        <Row>
                          <Col>
                            <span className="title">Suggested Search Keys</span>
                            <ul className="suggestion-list">
                              {newSearchField &&
                                newSearchField.map((s) => {
                                  return (
                                    <li>
                                      <div
                                        className="search-sugg-key"
                                        onClick={(e) => {
                                          searchSubmitHandler(e, s.search_text, props.pageType);
                                        }}
                                      >
                                        <div className="search-icon ">
                                          <Icon.Search />
                                        </div>
                                        {s.search_text}
                                      </div>
                                      {(s.most_pop === 0) ? <div
                                        className="right-arrw"
                                        onClick={() => {
                                          removeSuggKeyHandler(s.id);
                                        }}
                                      >
                                        <Icon.X />
                                      </div> : ""}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      <div className="search-overlay" onClick={(e) => {
                        searchSubmitHandler(e, searchField);
                      }}></div>
                    </>
                  )}
                </Form>
              </Col>
            ) : (
              ""
            )}

            {(props.pageType != "Setup" && (props.userType === "P" || props.userType === "S") && props.pageType !== "Asset Details" && props.pageType !== "Create Folder") && <>{props.pageType !== "Select Asset Type" &&
              props.pageType !== "Global Fields" &&
              props.pageType !== "CRM" && props.pageType !== "CRM Details" ? (
              <Col className="mx-md-1 ms-2  assets-add-new-btn">
                <Button
                  variant="info"
                  className="add-new-asset-btn"
                  onClick={() => {
                    navigate("/new-asset");
                  }}
                >
                  <span className="add-icon">
                    <Icon.Plus />
                  </span>
                  Add New Asset
                </Button>
              </Col>
            ) : (
              ""
            )}</>}

            {/*  {PROPS.PAGETYPE === "CRM" ? (
            <COL>
              <BUTTON
                VARIANT="INFO"
                CLASSNAME="ADD-NEW-ASSET-BTN"
                ONCLICK={() => {
                  NAVIGATE("/CRM/LEADS/IMPORT");
                }}
              >
                <SPAN CLASSNAME="CRM-HEADER-BTN-ICON">
                  <ICON.FILEEARMARKARROWDOWNFILL />
                </SPAN>
                IMPORT
              </BUTTON>
              <BUTTON
                VARIANT="INFO"
                CLASSNAME="ADD-NEW-ASSET-BTN ME-2"
                ONCLICK={() => {
                  DISPATCH(EXPORTLEADDETAILS(AUTHTOKEN))
                }}
              >
                <SPAN CLASSNAME="CRM-HEADER-BTN-ICON">
                  <ICON.FILEEARMARKARROWUPFILL />
                </SPAN>
                EXPORT
              </BUTTON>
            </COL>
          ) : (
            ""
          )} */}

            {/* {props.pageType === "Global Fields" ? (
            <>
              <Col style={{ textAlign: "right" }}>
                <span className="draft-saved">Draft Saved</span>
                <Button
                  variant="info"
                  className="add-new-asset-btn"
                  onClick={() => {
                    navigate("/new-asset");
                  }}
                >
                  <span className="upload-icon">
                    <Icon.Upload />
                  </span>
                  Upload All
                </Button>
              </Col>
            </>
          ) : (
            ""
          )} */}

            {/* {(!directPublish) && <>{props.pageType === "Global Fields" ? (
            <>
              <Col style={{ textAlign: "right" }}>
                <span className="draft-saved">Draft Saved</span>
                <Button
                  variant="info"
                  className="add-new-asset-btn"
                  onClick={() => {
                    navigate("/new-asset");
                  }}
                >
                  <span className="upload-icon">
                    <Icon.Upload />
                  </span>
                  Upload All
                </Button>
              </Col>
            </>
          ) : (
            ""
          )}</>} */}
          </Row>

          {props.pageType === 'Setup' && <Row className="setup-category">
            <Col className="p-0">
              <div>
                <ul>
                  {/* <li className={window.location.pathname === '/home/setup' ? 'list-active' : '' } onClick={() => { navigate('/setup') }}><span>Social</span></li> */}
                  {/* <li className={window.location.pathname.includes('/home/setup/microsite') ? 'list-active' : '' } onClick={() => { navigate('/setup/microsite') }}><span>Microsite</span></li> */}
                  <li className={window.location.pathname.includes('/home/setup/content') ? 'list-active' : ''} onClick={() => { navigate('/setup/content'); refreshPage(); }}><span>Content</span></li>
                  {/* <li className={window.location.pathname.includes('/home/setup/mobileApp') ? 'list-active' : '' } onClick={() => { navigate('/setup/mobileApp')}}><span>Mobile App</span></li> */}
                  {/* <li className={window.location.pathname.includes('/home/setup/crm') ? 'list-active' : '' } onClick={() => { navigate('/setup/crm')}}><span>CRM</span></li> */}
                  <li className={window.location.pathname.includes('/home/setup/userManagement') ? 'list-active' : ''} onClick={() => { navigate('/setup/userManagement'); refreshPage(); }}><span>User Management</span></li>
                  {(leaderboardFlag == true || true) && <li className={window.location.pathname.includes('/home/setup/leaderBoard') ? 'list-active' : ''} onClick={() => { navigate('/setup/leaderBoard'); refreshPage(); }}><span>Leaderboard</span></li>}
                </ul>
              </div>
            </Col>
          </Row>}
        </Container>
      </div>
    </div>
  );
};

export default Header;
