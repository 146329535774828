/* eslint-disable */
import Page from "../../../Page";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import React from "react";
import "./CreateFolder.css";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetLibraryCategoryList,
  fetchAssetLibraryHashtagList,
  createAssetFolder,
  updateAssetFolder,
  globalFilterAssetLibraryContentList
} from "../../../../redux/actions/actions";

const CreateFolder = (props) => {

  let navigate = useNavigate();

  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedHashtagList, setSelectedHashtagList] = useState([]);
  const [smartFolderOptions, setSmartFolderOptions] = useState(false);
  const [smartFolder, setSmartFolder] = useState("");

  let folderNameRef = useRef("");
  let folderDescRef = useRef("");

  const { authToken, purpose } = props;

  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );
  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );

  const folderDetails = useSelector((state) => state.assetLibrary.folderDetails);

  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);

  //console.log("folderDetails", folderDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetLibraryCategoryList(authToken));
    dispatch(fetchAssetLibraryHashtagList(authToken));
  }, []);

  useEffect(() => {
    if (purpose == "edit" && folderDetails) {
      folderNameRef.current.value = folderDetails?.folder_name;
      folderDescRef.current.value = folderDetails?.description;
      if (folderDetails.smart_folder == "1") {
        setSmartFolderOptions(true);
        setSmartFolder("1");
      }
      let cat = folderDetails?.category_details.map(
        ({ id: value, it_type: label }) => ({
          value,
          label,
        })
      );
      setSelectedCategoryList(cat);
      let hash = folderDetails?.tag_details.map(
        ({ id: value, inter_hashtag: label }) => ({
          value,
          label,
        })
      );
      setSelectedHashtagList(hash);
    }
  }, [folderDetails])

  let categories = [];
  if (assetLibraryCategoryList) {
    categories = assetLibraryCategoryList.map(
      ({ id: value, solution_type: label }) => ({
        value,
        label,
      })
    );
  }

  let hashtags = [];
  if (assetLibraryHashtagList) {
    hashtags = assetLibraryHashtagList.map(
      ({ id: value, tag_name: label }) => ({
        value,
        label,
      })
    );
  }

  const setSmartFolderOptionHandler = () => {
    setSmartFolderOptions((smartFolderOptions) => !smartFolderOptions);
    setSelectedHashtagList([]);
    setSelectedCategoryList([]);
    if (!smartFolderOptions) setSmartFolder(1);
    else setSmartFolder("");
  };

  const selectCategoryHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedCategoryList(categories);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedCategoryList([]);
    } else if (event.action === "deselect-option") {
      setSelectedCategoryList(value.filter((o) => o.value !== "*"));
    } else if (value.length === categories.length - 1) {
      setSelectedCategoryList(categories);
    } else {
      setSelectedCategoryList(value);
    }
  };

  const selectHashtagHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedHashtagList(hashtags);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedHashtagList([]);
    } else if (event.action === "deselect-option") {
      setSelectedHashtagList(value.filter((o) => o.value !== "*"));
    } else if (value.length === hashtags.length - 1) {
      setSelectedHashtagList(hashtags);
    } else {
      setSelectedHashtagList(value);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const categoryList = selectedCategoryList.map((category) => category.value);
    const hashtagList = selectedHashtagList.map((hashtag) => hashtag.value);
    if (purpose == 'create') {
      dispatch(
        createAssetFolder(
          authToken,
          smartFolder,
          categoryList,
          hashtagList,
          folderNameRef.current.value,
          folderDescRef.current.value
        )
      );
      navigate('/AssetLibrary');
    } else if (purpose == 'edit') {
      dispatch(updateAssetFolder(authToken,
        smartFolder,
        categoryList,
        hashtagList,
        folderNameRef.current.value,
        folderDescRef.current.value,
        folderDetails.id
      ));
      dispatch(globalFilterAssetLibraryContentList(authToken, globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, null, null, null));
      setTimeout(() => {
        navigate('/AssetLibrary');
        //navigate(-1);
      }, 2000);
    }
    folderNameRef.current.value = "";
    folderDescRef.current.value = "";
    setSmartFolderOptions(false);
    setSelectedCategoryList([]);
    setSelectedHashtagList([]);
    // setTimeout(() => {
    //   navigate("/AssetLibrary");
    // }, 2000);
  };

  props.pageTypeHandler("Create Folder");

  return (
    <>
      <div className="sub-header">
        <Row>
          <Col className="header-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: "/AssetLibrary" }}
                eventKey={"Asset Library"}
              >
                <div>Asset Library</div>
              </Breadcrumb.Item>
              <span>
                <Icon.ChevronRight />
              </span>
              <Breadcrumb.Item active>{purpose == 'edit' ? 'Update Folder' : 'Create New Folder'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
      <Page title="Create Folder">
        <Container className="create-folder">
          <Row>
            <Col>
              <h1 className="title">{purpose == 'edit' ? 'Update Folder' : 'Create a New Folder'}</h1>
              {/* <span className="text">
                Maecenas nulla interdum volutpat tortor. Nunc purus.
              </span> */}
            </Col>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    ref={folderNameRef}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTextarea">
                  <Form.Control
                    as="textarea"
                    rows={7}
                    placeholder="Description"
                    ref={folderDescRef}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSwitchBtn">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Smart Folder"
                    checked={smartFolderOptions}
                    onChange={() => setSmartFolderOptionHandler()}
                  />
                </Form.Group>
                {smartFolderOptions && (
                  <div className="smartFolder-multiSelect gap-2">
                     <div className="selectbox-maxsize">
                    <ReactMultiSelectCheckboxes
                      options={categories}
                      placeholderButtonLabel={selectedCategoryList.length > 0 ? selectedCategoryList.length + " Selected" : "Filter Category"}
                      values={selectedCategoryList}
                      defaultValue={selectedCategoryList}
                      setState={setSelectedCategoryList}
                      onChange={selectCategoryHandler}
                    />
                    </div>
                <div className="selectbox-maxsize">
                <ReactMultiSelectCheckboxes
                      options={hashtags}
                      placeholderButtonLabel={selectedHashtagList.length > 0 ? selectedHashtagList.length + " Selected" : "Filter Hashtags"}
                      values={selectedHashtagList}
                      defaultValue={selectedHashtagList}
                      setState={setSelectedHashtagList}
                      onChange={selectHashtagHandler}
                    />
                </div>
                  </div>
                )}
                <Button variant="info" type="submit">
                  {purpose == 'edit' ? 'Update Folder' : 'Create Folder'}
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Page>
    </>
  );
};

export default CreateFolder;
