import React from "react";
import Tags from "../shared/Tags";
import vector from '../../assets/setup-icon/Vector.png';
import FileInputComponent from "react-file-input-previews-base64";
import Multiselect from "multiselect-react-dropdown";
import { Form, Button } from "react-bootstrap";
import "./setup.css";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../shared/Toast";
import * as types from "../../constants/ActionTypes";
import { fetchAssetLibraryCategoryList } from "../../redux/actions/actions";
import { addCategoryData, editCategoryData, editContentData, addProductTypeData, addContentData } from "../../redux/actions/setupActions";

import { useState, useEffect } from "react";

const SingleScreen = (props) => {
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const category_all = useSelector((state) => state.assetLibrary.categoryList);
    // const is_navigate = useSelector((state) => state.setupScreen.is_navigate);
    const selected_value = useSelector((state) => state.setupScreen.selected_value);
    const special_user = useSelector((state) => state.setupScreen.special_user);
    const content_drop = useSelector((state) => state.setupScreen.content_drop.content_type_dropdown);
    const name_attr = useSelector((state) => state.setupScreen.name_attr);
    const type_attr = useSelector((state) => state.setupScreen.type_attr);
    const desc_attr = useSelector((state) => state.setupScreen.desc_attr);
    const image_url = useSelector((state) => state.setupScreen.curr_image);
    const schema_code = useSelector((state) => state.setupScreen.schema_code);
    const sub_id = useSelector((state) => state.setupScreen.sub_id);
    const parent_id = useSelector((state) => state.setupScreen.parent_id);
    const [renderForm, setRenderForm] = useState(false); 
    // const [navigationCheck, setNavigationCheck] = useState(false); 
    const [uploadedData, setUploadedData] = useState({}); 
    const [schemaCode, setSchemaCode] = useState(""); 
    const [isInternal, setIsInternal] = useState(false); 
    const [dropdownData, setDropdownData] = useState({});
    // const [parentValue, setParentValue] = useState(null);
    const [placeHolder, setplaceHolder] = useState({});
    const [categoryName, setCategoryName] = useState("");
    const [contentDesc, setContentDesc] = useState("");
    const [selectValues, setSelectValues] = useState(null);
    // const [selectedSubCatValues, setselectedSubCatValues] = useState(null);
    const static_content_type = [{id:4, content_type: "Brochure"}]
    const onSelectCategory = (selectedList, selectedItem) => {
      setSelectValues(selectedItem.id)
    }; 
    const setCategoryNameInput = (e) => {
      setCategoryName(e.target.value);
  }
    const setCategorySchemaCode = (e) => {
      setSchemaCode(e.target.value);
    }
    const setCategoryDescriptionInput = (e) => {
      setContentDesc(e.target.value);
    }
    const onRemoveCategory = (selectedList, removedItem) => {
      setSelectValues(null);
    };
    const handleChange=(e)=>{
      setIsInternal(!isInternal)
      dispatch({ type: types.SET_ATTR_TYPE, payload: isInternal === false ? 1 : 0 });
    }
    const saveData = async (data) => {  
        let imgObject = null;
        if (data && Object.keys(data).length !== 0) {
          imgObject = {
            base64: data.base64,
            file: {
                  name: data.name,
                  size: data.size,
                  type: data.type
              }          
          }
      }
      switch (props.subPageName) {
        case "addCategory":
            if (categoryName !== "") {
              await dispatch(addCategoryData(authToken, imgObject, categoryName, selectValues, special_user ? schemaCode : null));
              dispatch(fetchAssetLibraryCategoryList(authToken));
            } else {
              Toast("Please provide category name", "E");
            }
          break;
        case "editCategory":
            if (categoryName !== "") {
              await dispatch(editCategoryData(authToken, imgObject, categoryName, parent_id, type_attr, sub_id, special_user ? schemaCode || schema_code : null));
              dispatch(fetchAssetLibraryCategoryList(authToken));
            } else {
              Toast("Please update the category name", "E");
            }
          break;
        case "addContent":
            if (isInternal === false) {
              if (imgObject !== null && categoryName !== "" && contentDesc !== "" && selectValues !== null) {
                await dispatch(addContentData(authToken, imgObject, categoryName, contentDesc, selectValues, isInternal));
              } else {
                Toast("All fields are mandatory", "E");
              }
            } else {
              if (categoryName !== "") {
                await dispatch(addContentData(authToken, null, categoryName, null, 4, isInternal));
              }else {
                Toast("Please provide content name", "E");
              }
            }
          break;
        case "editContent":
            if (isInternal === false) {
              if (categoryName !== "") {
                await dispatch(editContentData(authToken, imgObject, categoryName, selectValues || parent_id, isInternal, sub_id, contentDesc || desc_attr));
              }
              else {
                Toast("Please update the content name", "E");
              }
            } else {
              if (categoryName !== "") {
                await dispatch(editContentData(authToken, null, categoryName, parent_id, isInternal, sub_id, null));
              }
              else {
                Toast("Please update the content name", "E");
              }
            }
          break;
        case "addProductType":
            if (categoryName !== "" && contentDesc !== "") {
            await dispatch(addProductTypeData(authToken, categoryName, contentDesc ));
          } else {
            Toast("Product Type Name and Description is Mandatory", "E");
          }  
          break;
        case "editProductType":
            if (categoryName !== "" || contentDesc !== "") {
              await dispatch(addProductTypeData(authToken, categoryName, contentDesc, sub_id ));
            } else {
              Toast("Please Update Product type name or description ", "E");
            }  
            break;
        default:
          console.log('End');
          break;
      }
      setRenderForm(true);
    }
    // useEffect(() => {
    //   setNavigationCheck(is_navigate)
    //   console.log("update",navigationCheck)
    // }, [is_navigate])
    
    useEffect(() => {
      // setNavigationCheck(is_navigate)
      console.log("user",special_user);
      if (props.subPageName === "addCategory") {
          setplaceHolder({ name: "Category Name", type: "Select Parent Category" });
          setDropdownData({data:category_all, display:"solution_type"});
      } else if (props.subPageName === "editCategory") {
          setSchemaCode(schema_code);
          setplaceHolder({name:"Category Name",type:"Select Parent Category"});
          setCategoryName(name_attr);
          setDropdownData({data:category_all, display:"solution_type"});
      } else if (props.subPageName === "addContent") {
          setplaceHolder({ name: "Content Name", type: "Select Content Type" });
          setDropdownData({data:content_drop, display:"content_type"});
      } else if (props.subPageName === "editContent") {
          setplaceHolder({ name: "Content Name", type: "Select Content Type" });
          setCategoryName(name_attr);
          setContentDesc(desc_attr);
          setIsInternal(type_attr === 0 ? false : true);
          setDropdownData({data:content_drop, display:"content_type"});
      } else if (props.subPageName === "addProductType") {
          setplaceHolder({ name: "Product Type Name", type: "" });
      } else if (props.subPageName === "editProductType") {
          setplaceHolder({ name: "Product Type Name", type: "" });
          setCategoryName(name_attr);
          setContentDesc(desc_attr);
      } else {
          setplaceHolder({ name: "Name", type: "Select Type" });
          setDropdownData({data:content_drop, display:"content_type"});
      }
      dispatch(fetchAssetLibraryCategoryList(authToken));
      setRenderForm(true);
      console.log("special_user",special_user);
      console.log("props.subPageName",props.subPageName);
      console.log("selected_value",selected_value);
      console.log("selectValues", selectValues);
      console.log("type_attr", type_attr);
    }, [])
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.SET_NAME_ATTR, payload: "" });
          dispatch({ type: types.SET_ATTR_TYPE, payload: null });
          dispatch({ type: types.SET_SUB_ID, payload: null });
          dispatch({ type: types.SET_PARENT_ID, payload: null });
          dispatch({ type: types.SET_SELECTED_VALUE, payload: [] });
          dispatch({ type: types.SET_SCHEMA_CODE, payload: null });
          dispatch({ type: types.SET_IMAGE, payload: "" });
          dispatch({ type: types.SET_DESC_ATTR, payload: "" });
          dispatch({ type: types.ROUTE_CHANGE_FLAG, payload: false });
        }
    }, [])

    return (
      <div className="single-screen">
        <span onClick={()=>{window.history.back()}}>
          <img className="rotate-left" src={vector} alt="icon" />
          Back
        </span>
        <h2>{ props.innerTitle }</h2>
        {renderForm && <Form>
            <Form.Control
                className="add-name my-3"
                value={categoryName}
                type="text"
                placeholder={placeHolder.name}
                onChange={setCategoryNameInput}
            />
            {(type_attr !== 1 || props.subPageName === "addCategory" || props.pageName === "content_list") && props.pageName !== "content_p_type" &&
              <Multiselect
                  className="drop-down mb-3" // setDropdownData({data:category_all, display:"solution_type"});
                  options={dropdownData.data}
                  selectedValues={isInternal === false ? selected_value : static_content_type}
                  disable={(isInternal === true && (props.pageName === "content_list"))}
                  onSelect={onSelectCategory}
                  onRemove={onRemoveCategory}
                  singleSelect
                  displayValue={dropdownData.display}
                  placeholder={placeHolder.type}
            />}
          {isInternal === true && <p className="note">Note: In case of internal, content type will be "Brochure" only</p>}
            {special_user === true && (props.subPageName === "addCategory" || props.subPageName === "editCategory") && type_attr !== 1 && (selected_value?.length > 0 || selectValues !== null) && <Form.Control
                className="add-name my-3"
                value={schemaCode}
                type="text"
                placeholder="Schema Code"
                onChange={setCategorySchemaCode}
            />}
            {isInternal === false && (props.pageName === "content_list" || props.pageName === "content_p_type") && <Form.Group>
              <Form.Control
                className="add-name my-3"
                rows={3}
                value={contentDesc}
                as="textarea"
                placeholder="Description"
                onChange={setCategoryDescriptionInput}
              />
            </Form.Group>}
            { props.pageName === "content_list" && <div className="switch-button">
                <span>Internal</span>
                <Form.Check 
                  className="d-inline"
                  type="switch"
                  id="custom-switch"
                  defaultChecked={isInternal}
                  onChange={handleChange}
                />  
            </div>}
            {isInternal === false && props.pageName !== "content_p_type" && <div className="button-image py-2 mb-3">
              <span>Image</span>
                <div>
                  { props.subPageName !== "addCategory" && props.subPageName !== "addContent" && type_attr !== 2 && image_url !== "" && Object.keys(uploadedData).length === 0 && <img className="main-img" src={image_url} alt="preview"/>}
                  <FileInputComponent
                    labelText=""
                    multiple={false}
                    callbackFunction={(file_arr) => {
                      setUploadedData(file_arr);
                      console.log(file_arr);
                    }}
                    accept="image/*"
                  />
                </div>
            </div>}
            { false && props.subPageName !== "addContent" && props.subPageName !== "editContent" && <Tags />}
            <Button variant="primary" className="save-button" size="lg" onClick={()=>{saveData(uploadedData)}}>
                Save Changes
            </Button>
        </Form>}
      </div>
    );
};

export default SingleScreen;
