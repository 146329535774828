/* eslint-disable */
import * as Icon from "react-bootstrap-icons";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetBookmark,
  fetchAssetLibraryContentList,
  fetchFolderContentList,
  fetchContent,
  globalFilterAssetLibraryContentList,
} from "../redux/actions/actions";

const Bookmark = (props) => {
  const dispatch = useDispatch();

  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  const currAsset = useSelector((state) => state.assetLibrary.currentAsset);
  const currAssetDetails = useSelector(
    (state) => state.assetLibrary.currentAssetDetails
  );
  
  const handleBookmark = (action) => {
    dispatch(setAssetBookmark(props.authToken, props.id, props.type, action));
    if (currAsset) {
      setTimeout(() => {
        dispatch(fetchContent(props.authToken, currAsset));
      }, 2000);
    } else if (currFolderId) {
      setTimeout(() => {
        dispatch(
          globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName)
        );
      }, 2000);
    } else {
      setTimeout(() => {
        dispatch(
          // fetchAssetLibraryContentList(props.authToken)
          globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName)
        );
      }, 2000);
    }
  };

  return (
    <>
      {props.bookmarkStatus === 1 && (
        <Icon.BookmarkCheckFill
          className="asset-bookmark check"
          onClick={() => {
            if(!props.share)
            handleBookmark("remove_fav");
          }}
        />
      )}
      {props.bookmarkStatus === 0 && (
        <Icon.Bookmark
          className="asset-bookmark"
          onClick={() => {
            if(!props.share)
            handleBookmark("add_fav");
          }}
        />
      )}
    </>
  );
};

export default Bookmark;
