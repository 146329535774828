/* eslint-disable */
import { Button, Dropdown, Form, Table, Modal } from "react-bootstrap";
import "./AssetListCard.css";
import * as Icon from "react-bootstrap-icons";
import Bookmark from "../../../Bookmark";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContent,
  deleteContent,
  duplicateContent,
  fetchAssetLibraryContentList,
  //fetchFolderContentList,
  globalFilterAssetLibraryContentList,
  setSelectedAssetType,
  downloadFile,
  setPublishType,
} from "../../../../redux/actions/actions";

const AssetListCard = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const [assetChecked, setAssetChecked] = useState(false);
  const [baseUrlAsset, setBaseUrlAsset] = useState("");

  const [popupMsg, setpopupMsg] = useState(null);
  const [popupAction, setpopupAction] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const imgUrl = props.content.thumb_image;
  let partnerCategory = [];
  let userCategory = [];
  if (props.content.partner_category) {
    partnerCategory = props.content.partner_category.slice(0, 2);
  }
  if (props.content.users_category) {
    userCategory = props.content.users_category.slice(0, 2);
  }
  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const microsite_flag = useSelector((state) => state.assetLibrary.microsite_flag);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  
  const handleDownload = (url) => {
    dispatch(downloadFile(props.authToken,url))
  }
  
  useEffect(() => {
    let baseUrl
    let tempUrl = `${window.location.href}`;
    if (tempUrl.search("localhost") > 0) {
      baseUrl = "https://app.spdevmfp.com/";
      setBaseUrlAsset(baseUrl);
    } else {
      baseUrl = window.location.href;
      baseUrl = baseUrl.split('/')[0] + "/" + baseUrl.split('/')[1] + "/" + baseUrl.split('/')[2] + "/";
      setBaseUrlAsset(baseUrl);
    }
  }, [])
  
  useEffect(() => {
    if (props.checked === 0) {
      setAssetChecked(false);
    }
  }, [props.checked]);
  
  // useEffect(() => {
  //   setReorderShow()
  // }, [])
  

  return (
    <Form
      className={(microsite_flag == 1 && "microsite-css")}
      {...props.provider.draggableProps}
      ref={props.provider.innerRef}
    >
      <div
        className={
          "asset-list-card " +
          (props.assetChecked > 0 || assetChecked ? "selected-card" : "")
        }
      >
        <Table responsive className={(props.userType === "P" || props.userType === "S") ? "" : "child-user"}>
          <tbody>
            <tr>
              {microsite_flag == 1 && <>
                {props.reorderList === true ? <td
                {...props.provider.dragHandleProps}
              >
                <Icon.GripHorizontal color="#0000001f" style={{fontSize:"18px"}}/>
              </td>
                :
              <td>
                <Icon.GripHorizontal color="#0000001f" style={{fontSize:"18px"}}/>
              </td>}
              </>}
              <td style={(props.userType === "P" || props.userType === "S" || props.userType === "N") ? {} : {"max-width": "20px"}}>
                <Form.Check
                  type="checkbox"
                  className="check-box"
                  checked={assetChecked}
                  value={props.content.content_id}
                  onChange={(e) => {
                    setAssetChecked(!assetChecked);
                    props.handleCheck(
                      e,
                      props.content.content_id,
                      props.content.type
                    );
                  }}
                />
              </td>
              <td style={(props.userType === "P" || props.userType === "S" || props.userType === "N") ? {} : {"max-width": "55px"}}>
                {/* <div className="x-count">x2</div> */}
                <img
                  className="list-card-img"
                  variant="top"
                  src={imgUrl}
                  alt="asset-content-img"
                />
              </td>

              <td>
                <Bookmark
                  authToken={props.authToken}
                  bookmarkStatus={props.content.bookmark}
                  id={props.content.content_id}
                  type={props.content.type}
                />
                {/* <img className="list-card-tag" src={tagImgUrl} /> */}
              </td>
              <td>
                <div className="card-list-details position-relative">
                  <span className="card-title">
                    <div
                      className="solution_types"
                      title={
                        props.content.solution_types &&
                        props.content.solution_types.map((type) => {
                          return type.it_type + ", ";
                        })
                      }
                    >
                      {props.content.solution_types.length > 1
                        ? props.content.solution_types.map((type) => {
                            return <>{type.it_type + ", "}</>;
                          })
                        : props.content.solution_types.map((type) => {
                            return <>{type.it_type}</>;
                          })}
                    </div>
                  </span>
                  <a
                    href={() => false}
                    onClick={() => {
                      // props.assetDetailsHandler({
                      //   [props.content.type]: [props.content.content_id],
                      // });
                      dispatch(
                        fetchContent(props.authToken, {
                          [props.content.type]: [props.content.content_id],
                        })
                      );
                      navigate("/asset-details");
                    }}
                  >
                    <span className="card-text">
                      {props.content.content_title}
                    </span>
                  </a>
                  {(props.userType !== "P" && props.userType !== "S") && <a href={`${baseUrlAsset}framework/api/file?url=${props.content.link}`} target="blank"><Icon.Download className="download-icon-asset list-d-icon"/></a> }
                </div>
              </td>
              <td className="vr-border">
                <div className="vr-divider"></div>
              </td>
              <td style={(props.userType === "P" || props.userType === "S" || props.userType === "N") ? {} : {"width": "105px"}}>
                <div className="list-card-tags">
                  {/* {!props.content.contenttype_status && (
                    <div className="new-card-tag">NEW</div>
                  )} */}
                  {(props.content.new === 1) && <div className="new-card-tag">NEW</div>}
                  <div>{props.content.content_type}</div>
                  {(props.content.cobrand === 1) && <div>CO-BRANDED</div>}
                  {/* {props.content.contenttype_status && (
                    <div>{props.content.contenttype_status}</div>
                  )} */}
                </div>
              </td>
              {(props.userType === "P" || props.userType === "S") ? (
                <>
                  {" "}
                  {props.content.status && (
                    <>
                      <td className="vr-border">
                        <div className="vr-divider"></div>
                      </td>

                      <td>
                        <span className="card-title status">
                          {/* {(props.userType === "C") ? "Published" : props.content.status} */}
                          {props.content.expired == 1 ? "Expired" : props.content.status}
                          <span>{props.content.status === "Scheduled" ? props.content.scheduled_date : props.content.activity_date}</span>
                        </span>
                      </td>
                    </>
                  )}
                  <td className="vr-border">
                    <div className="vr-divider"></div>
                  </td>
                  {(props.content.status === "Published") && <>
                    {partnerCategory.length > 0 && (
                      <>
                        <td>
                          <div className="card-footer-action partners">
                            {partnerCategory.map((category) => {
                              const partnerName = category.name.charAt(0);
                              partnerName.toUpperCase();
                              return (
                                <>
                                  <div className="circle">{partnerName}</div>
                                  {/* <div className="square">Edu.</div> */}
                                </>
                              );
                            })}

                            {partnerCategory.length > 1 && (
                              <div
                                className="count"
                                title={
                                  partnerCategory &&
                                  partnerCategory.map(
                                    (category) => category.name + ", "
                                  )
                                }
                              >
                                {/* +{props.content.partner_category.length - 2} */}
                                {props.content.partner_category.length - 2 > 0
                                  ? `+${props.content.partner_category.length - 2}`
                                  : ""}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="vr-border">
                          <div className="vr-divider"></div>
                        </td>
                      </>
                    )}
                    {userCategory.length > 0 && (
                      <>
                        <td>
                          <div className="card-footer-action partners">
                            {userCategory.map((category) => {
                              const userName = category.name.charAt(0);
                              userName.toUpperCase();
                              return (
                                <>
                                  <div className="circle">{userName}</div>
                                  {/* <div className="square">Edu.</div> */}
                                </>
                              );
                            })}

                            {userCategory.length > 0 && (
                              <div
                                className="count"
                                title={
                                  userCategory &&
                                  userCategory.map(
                                    (category) => category.name + ", "
                                  )
                                }
                              >
                                +{props.content.users_category.length - 2}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="vr-border">
                          <div className="vr-divider"></div>
                        </td>
                      </>
                    )}
                  </>}
                  
                  {props.content.status && (
                    <td>
                      <div className="card-footer-action list-card">
                        {(props.content.status === "Published" || props.content.status !== "Scheduled") ? (
                          <Button size="sm" variant="secondary" disabled>
                            {props.content.status}
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="outline-info"
                            onClick={() => {
                              // props.publishAssetContent(
                              //   props.content.content_id,
                              //   props.content.type,
                              //   "1"
                              // );
                              dispatch(setPublishType(true));
                              dispatch(
                                setSelectedAssetType({
                                  id: props.content.content_id,
                                  article_type: props.content.type,
                                  type: props.content.type,
                                })
                              );
                              dispatch(
                                fetchContent(props.authToken, {
                                  [props.content.type]: [props.content.content_id],
                                })
                              );
                              setTimeout(() => {
                                navigate("/new-asset/publish-asset");
                              }, 1000);
                            }}
                          >
                            Publish
                          </Button>
                        )}
                      </div>
                    </td>
                  )}
                </>
              ) : (
                ""
              )}
              <td>
                {(props.userType === "P" || props.userType === "S") ? (
                  <div className="card-footer-action list-card">
                    <Dropdown className="action-btn">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <Icon.ThreeDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setpopupAction("Duplicate");
                            setpopupMsg(
                              "Are you sure that you want to duplicate this Asset?"
                            );
                            handleShow();
                          }}
                        >
                          Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setpopupAction("Delete");
                            setpopupMsg(
                              "Are you sure that you want to delete this Asset?"
                            );
                            handleShow();
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="share-btn" onClick={()=>props.shareHandleShow(props.content.content_id, props.content.content_type)}>
                    Share
                    <span>
                      <Icon.ChevronRight />
                    </span>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <Modal show={show} onHide={handleClose} >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{"textAlign": "center"}}>
            <div className="alert-icon">
              <Icon.ExclamationTriangle />
            </div>
            {/* Are you sure that you want to delete this Asset? */}
            {popupMsg}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="info"
              onClick={() => {
                if (popupAction === "Delete") {
                  dispatch(
                    deleteContent(
                      props.authToken,
                      props.content.content_id,
                      props.content.type
                    )
                  );
                  props.handleCheck(null);
                } else if (popupAction === "Duplicate") {
                  dispatch(
                    duplicateContent(
                      props.authToken,
                      props.content.content_id,
                      props.content.type
                    )
                  );
                }
                setTimeout(() => {
                  if(currFolderId) {
                    dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
                  } else {
                    dispatch(fetchAssetLibraryContentList(props.authToken));
                  }
                }, 2000);
                handleClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="info"
              onClick={handleClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Form>
  );
};

export default AssetListCard;
