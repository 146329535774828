/* eslint-disable */
import { Nav, Row, Col } from "react-bootstrap";
import "./QuickFilters.css";
import * as Icon from "react-bootstrap-icons";
import { useEffect, useState } from "react";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetLibraryQuickFilter,
  globalFilterAssetLibraryContentList,
} from "../../../../redux/actions/actions";

const QuickFilters = (props) => {
  const dispatch = useDispatch();

  const [activeId, setactiveId] = useState("All");
  const isLoadings = useSelector((state) => state.assetLibrary.filter_loading);
  const quickFilters = useSelector((state) => state.assetLibrary.quickFilters);
  const currQuickFilterId = useSelector(
    (state) => state.assetLibrary.quickFilterId
  );
  const currQuickFilterValue = useSelector(
    (state) => state.assetLibrary.quickFilterType
  );
  const globalSearchKey = useSelector(
    (state) => state.assetLibrary.globalSearchKey
  );
  //const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);
  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  const end_limit = useSelector((state) => state.assetLibrary.end_limit);

  const folderDetails = useSelector(
    (state) => state.assetLibrary.folderDetails
  );

  useEffect(() => {
    if (!currQuickFilterId) {
      setactiveId("All");
    } else {
      setactiveId(currQuickFilterValue);
    }
  }, [currQuickFilterId]);

  return (
    <>
      {
        isLoadings ?
          <Nav
            className="col-md-12 d-none d-md-block bg-light quick-filters"
            activeKey="/"
          >
            <h5 style={{height:'15px'}} className="shine mb-2"></h5>
            <Nav.Item className="mb-2" style={{borderRadius: "5px", padding: "10px 100px 10px 5px", border: "1px solid #e3e3e3"}}>
              <Nav.Item
                className="shine"
                style={{ height: '20px' }}
              >
              </Nav.Item>
            </Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
            <Nav.Item style={{height:'20px'}} className="shine mb-3"></Nav.Item>
          </Nav>
          :
          <Nav
        className="col-md-12 d-none d-md-block bg-light quick-filters"
        activeKey="/"
      >
        <h5>Quick Filter</h5>
        <Nav.Item>
          <Nav.Link
            href="#"
            className={activeId === "All" && "active"}
            onClick={() => {
              setactiveId("All");
              //dispatch(setAssetLibraryQuickFilter(props.authToken, null, "All"));
              dispatch(
                globalFilterAssetLibraryContentList(
                  props.authToken,
                  globalSearchKey,
                  null,
                  cFilterKey,
                  sFilterKey,
                  svFilterKey,
                  currFolderId,
                  currFolderType,
                  currFolderName
                )
              );
            }}
          >
            All
            {activeId === "All" && (
              <span>
                <Icon.ChevronRight />
              </span>
            )}
          </Nav.Link>
        </Nav.Item>
        {quickFilters &&
          quickFilters.length > 0 &&
          quickFilters.map((filter) => {
            return (
              <Nav.Item key={filter.id}>
                <Nav.Link
                  className={activeId === filter.article_type && "active"}
                  eventKey={filter.id}
                  onClick={() => {
                    setactiveId(filter.article_type);
                    //dispatch(setAssetLibraryQuickFilter(props.authToken, filter.id, filter.filter_value));
                    dispatch(
                      globalFilterAssetLibraryContentList(
                        props.authToken,
                        globalSearchKey,
                        { id: filter.id, value: filter.article_type },
                        cFilterKey,
                        sFilterKey,
                        svFilterKey,
                        currFolderId,
                        currFolderType,
                        currFolderName,
                        0,
                        filter?.article_type !== "Microsite" ? 20 : end_limit
                      )
                    );
                  }}
                >
                  {filter.article_type}
                  {activeId === filter.article_type && (
                    <span>
                      <Icon.ChevronRight />
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            );
          })}

        {currFolderId && (
          <div className="mt-4 folder-details-list">
            <div className="h5">Folder Details</div>
            <div className="mt-3 h6 text-muted">
              Categories : <br />{" "}
              <div className="folder-types">
                {folderDetails?.category_details.map((cat, i) => {
                  return (
                    <>
                      {i !== 0 && ", "} {cat.it_type}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="mt-3 h6 text-muted">
              HashTags : <br />
              <div className="folder-types">
                {folderDetails?.tag_details.map((hash, i) => {
                  return (
                    <>
                      {i !== 0 && ", "} {hash.inter_hashtag}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Nav>
    }
    </>
  );
};

export default QuickFilters;
